<template>
  <div class="mentor-form">
    <div>
      <div class="row">
        <div
          v-if="userOrganizations.length"
          class="col-md-8 col-xs-12">
          <p><b>Ваши организации</b></p>

          <ul class="orgs">
            <li
              v-for="org in userOrganizations"
              :key="org.id"
              class="orgs__item">
              <div class="orgs__name">
                {{ org.full_name || org.short_name || "Название организации" }}
              </div>
              <div class="orgs__address">{{ org.address }}</div>
              <div v-if="!org.is_permitted">Соглашение не подписано</div>
            </li>
          </ul>
        </div>
        <div
          v-else
          class="col-md-8 col-xs-12">
          <div>
            Когда мы&nbsp;регистрируем наставников на&nbsp;НТО, мы&nbsp;собираем
            информацию об&nbsp;организации, в&nbsp;которой они работают. Если
            вы&nbsp;готовите участников в&nbsp;школе, укажите эту школу, если
            работаете в&nbsp;кружке&nbsp;&mdash; кружок и&nbsp;т.&nbsp;п. Если
            вы&nbsp;работаете вне организаций (например, готовите участников
            дома), укажите любое название, которое вам нравится&nbsp;&mdash;
            к&nbsp;примеру,
            <a
              class="link link--pseudo"
              @click.prevent="setOrgName('Домашняя подготовка')"
              >&laquo;Домашняя подготовка&raquo;</a
            >.
          </div>
        </div>
      </div>
      <div v-if="!userOrganizations.length">
        <hr class="hr" />
        <ValidationObserver
          ref="form"
          tag="div">
          <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-12 mb-xs">
              <label
                for="org_name"
                class="form-label"
                >Название организации*</label
              >
              <ValidationProvider
                v-slot="{ errors }"
                rules="required">
                <BaseInput
                  id="org_name"
                  v-model="newOrganizationData.full_name"
                  theme="reg"
                  :errors="errors"
                  placeholder="" />
              </ValidationProvider>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12 mb-xs">
              <label class="form-label">Адрес организации*</label>
              <ValidationProvider
                v-slot="{ errors }"
                mode="aggressive"
                rules="required">
                <AddressAutocomplete
                  v-model="newOrganizationData.address"
                  placeholder="Укажите город"
                  :errors="errors"
                  :search-options="{
                    from_bound: { value: 'city' },
                    locations: [{ country: '*' }],
                    to_bound: { value: 'settlement' },
                  }"
                  theme="reg" />
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="hr"></div>
      <div
        v-if="error"
        class="form-error mb-m">
        {{ error }}
      </div>
      <!-- /Education Create Form -->
      <div class="btn-row btn-row--reverse">
        <BaseButton
          id="reg-btn-track-school"
          theme="reg"
          tag="router-link"
          title="Вернуться к выбору роли"
          :to="{ name: 'registration', query: $route.query }"
          class="mentor-form__btn"
          >Назад</BaseButton
        >&emsp;
        <BaseButton
          class="mentor-form__btn"
          @click.prevent="handleSubmit"
          >Зарегистрироваться</BaseButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { MENTOR_ROLE } from "@/constants";
import { talentRequest, request } from "@/services/api";
import AddressAutocomplete from "@/components/autocomplete/AddressAutocomplete";

export default {
  name: "RegistrationMentorForm",
  components: {
    AddressAutocomplete,
  },
  props: {
    talentUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userOrganizations: [],
      error: "",
      newOrganizationData: {
        full_name: "",
        address: "",
      },
      organizationCreated: false,
      pending: false,
    };
  },
  async created() {
    this.getOrganizations();
  },
  methods: {
    setOrgName(name) {
      this.newOrganizationData.full_name = name;
    },
    async handleSubmit() {
      const { next } = this.$route.query;
      if (this.pending) return;
      this.pending = true;
      this.error = "";
      if (!this.userOrganizations.length && !this.organizationCreated) {
        const isValid = await this.$refs.form.validate();
        if (!isValid) {
          this.pending = false;
          return;
        }
        try {
          await this.createOrganization();
          this.organizationCreated = true;
        } catch (error) {
          this.error = error.message;
          this.pending = false;
          return;
        }
      }
      try {
        await request({
          method: "POST",
          url: "/user/register",
          data: {
            role: MENTOR_ROLE,
          },
        });

        window.dataLayer?.push({
          event: "ntoRegformEventRole",
          eventCategory: "nto_regform",
          eventAction: "form_filled_mentor",
          userRole: this.organizationCreated
            ? "mentor_without_org"
            : "mentor_with_org",
        });
      } catch (error) {
        this.pending = false;
        this.error = error.message;
      }
      this.$router.push(
        next
          ? next
          : {
              name: "mentor-index",
            }
      );
    },
    async createOrganization() {
      await talentRequest({
        method: "POST",
        url: `/api/users/${this.talentUser.id}/organizations/`,
        data: {
          ...this.newOrganizationData,
          short_name: this.newOrganizationData.full_name,
        },
      });
      window.dataLayer?.push({
        event: "ntoProfileEvent",
        eventCategory: "nto_profile",
        eventAction: "mentor_created_new_org",
      });
    },
    async getOrganizations() {
      const { data } = await talentRequest({
        url: `/api/users/${this.talentUser.id}/organizations/`,
        params: {
          limit: 100,
          offset: 0,
        },
      });
      const hasOrg = data.results?.length > 0;
      window.dataLayer?.push({
        event: "ntoRegformEventRole",
        eventCategory: "nto_regform",
        eventAction: "choose_role",
        userRole: hasOrg ? "mentor_with_org" : "mentor_without_org",
        userChoice: "selected_role",
      });
      this.userOrganizations = data.results;
    },
  },
};
</script>
